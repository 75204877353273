import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Settings } from 'src/app/shared/models/settings.model';
import { IconsModule } from 'src/app/shared/modules/icons.module';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Subscription } from 'rxjs';
import { MappedAssets } from 'src/app/shared/models/asset.model';

const DEFAULT_DURATION = 200;

@Component({
  selector: 'app-mobilemenu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    IconsModule
  ],
  templateUrl: './mobilemenu.component.html',
  styleUrls: ['./mobilemenu.component.scss'],
  animations: [
    trigger('open', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease'))
    ])
  ]

})
export class MobilemenuComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription = new Subscription();
  @Input() drawer;
  settings: Settings;
  menuItems: any[];
  assets: MappedAssets;

  constructor(
    private appService: AppService,
    private filterService: FilterService,
  ) {
  }

  ngOnInit(): void {
    this.menuItems = this.appService.sideMenuItems;
    this.settings = this.appService.settings;
    this.assets = this.appService.mappedAssets;
  }

  getFirstPhone() {
    return this.appService.getFirstPhone();
  }

  closeOthers(open) {
    this.menuItems.forEach((item, index) => {
      if (item.sub && open !== index) item.sub.active = false;
      return item;
    });
  }

  goToFilteredCourses(type) {
    this.filterService.setQueryParam('tip', type, '/cursuri');
  }

  downloadFile(path, name) {
    this.appService.downloadFile(path, name);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
