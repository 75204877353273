import { RouteReuseStrategy, RouterModule, provideRouter } from '@angular/router';
import { APP_ID, ApplicationConfig, importProvidersFrom, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { APP_ROUTES } from './app.routes';
import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserStateInterceptor } from './shared/interceptors/browser-state.interceptor';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { FileSaverModule } from 'ngx-filesaver';
import { AppService } from './app.service';
import { CoursesResolve } from './courses/courses.resolve';
import { CourseWithSessionResolve } from './courses/course/course-with-session.resolve';
import { CourseWithoutSessionResolve } from './courses/course/course-without-session.resolve';
import { LocationResolve } from './locations/location.resolve';
import { ResortResolve } from './resorts/resort.resolve';
import { PageResolve } from './page/page.resolve';
import { EnrolledResolve } from './enroll/enrolled.resolve';
import { Observable } from 'rxjs';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { MobilemenuComponent } from './shared/components/header/mobilemenu/mobilemenu.component';
import { StickyButtonComponent } from './shared/components/sticky-button/sticky-button.component';
import { CustomRouteReuseStrategy } from './shared/services/route.strategy';
import { EnrolledErrorResolve } from './enroll/enrolled-error.resolve';
import { SitemapResolve } from './sitemap/sitemap.resolve';

export function initializeApp(appService: AppService) {
    return (): Observable<any> => {
        return appService.init();
    }
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      domain: environment.DOMAIN, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
      popup: {
        background: '#000000c9',
      },
      button: {
        background: '#6dc1c0',
        text: '#ffffff'
      }
    },
    position: 'bottom-right',
    theme: 'block',
    type: 'opt-in',
    content: {
      message: "Acest site folosește module tip cookie sau tehnologii similare. Apăsând butonul \"Acceptă\" sau navigând pe acest website, ești de acord să permiți colectarea de informații prin cookie-uri sau tehnologii similare.",
      dismiss: 'Acceptă',
      allow: 'Acceptă',
      deny: "Refuză",
      link: "Mai multe detalii",
      href: "https://cookiesandyou.com"
    }
  };

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(APP_ROUTES),
        { provide: environment.BACKEND_URL, useValue: environment.BACKEND_URL },
        { provide: environment.BACKEND_FULL_URL, useValue: environment.BACKEND_FULL_URL },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        { provide: APP_ID, useValue: 'lecto-ui' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppService],
            multi: true
        },
        AppService,
        CoursesResolve,
        CourseWithSessionResolve,
        CourseWithoutSessionResolve,
        LocationResolve,
        ResortResolve,
        PageResolve,
        EnrolledResolve,
        EnrolledErrorResolve,
        SitemapResolve,
        { provide: 'googleTagManagerId', useValue: environment.gtm.id },
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            RouterModule.forRoot(APP_ROUTES, {
                bindToComponentInputs: true,
                onSameUrlNavigation: 'reload',
                initialNavigation: 'enabledBlocking',
            }),
            HttpClientModule,
            TransferHttpCacheModule,
            FileSaverModule,
            MobilemenuComponent,
            StickyButtonComponent,
            NgcCookieConsentModule.forRoot(cookieConfig),
        ),
    ]
};
