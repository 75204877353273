<div class="side-content">
    <div class="side-header offset-logo mb-20">
        <div class="row align-items-center">
            <div class="col-9">
                <a name="home-link" [routerLink]="['/']">
                    <img src="assets/img/logo/lectoform-logo-new-small.webp" alt="Lectoform Consulting" title="Lectoform Consulting" width="170" height="43">
                </a>
            </div>
            <div class="col-3 text-end"><button name="menu=toggle-button" class="side-info-close" (click)="drawer.toggle()"><fa-icon
                        [icon]="['fas', 'times']"></fa-icon></button>
            </div>
        </div>
    </div>

    <nav class="mobile-menu">
        <ul>
            <li *ngFor="let item of menuItems; let i = index;" [class.opened-menu]="item.sub && item.sub.active">

                <a name="menu-item" class="main-item d-flex flex-row justify-content-between align-items-center" *ngIf="item.sub"
                    (click)="item.sub.active = !item.sub.active; closeOthers(i)">
                    <span>{{item.title}}</span>
                    <fa-icon [icon]="['fas', 'angle-down']" class="mr-10"></fa-icon>
                </a>
                <a name="menu-item" class="main-item d-flex flex-row justify-content-between align-items-center" *ngIf="!item.sub"
                    [routerLink]="[item.path]">
                    <span>{{item.title}}</span>
                </a>

                <ng-container *ngIf="item.sub">
                    <ul class="sub-menu" [@open]="item.sub.active">
                        <li *ngFor="let sub of item.sub.items">
                            <a name="sub-menu-item" *ngIf="!sub.path && sub.title === 'Cursuri Online'" [routerLink]="''"
                                (click)="goToFilteredCourses('online')">{{sub.title}}</a>
                            <a name="sub-menu-item" *ngIf="!sub.path && sub.title === 'Cursuri Locale'" [routerLink]="''"
                                (click)="goToFilteredCourses('local')">{{sub.title}}</a>
                            <a name="sub-menu-item" *ngIf="!sub.path && sub.title === 'Ghid Hotelier'" [routerLink]=""
                                (click)="downloadFile(assets.download_ghid_hotelier?.path, assets.download_ghid_hotelier?.name)">{{
                                assets.download_ghid_hotelier?.label ? assets.download_ghid_hotelier?.label :
                                sub.title}}</a>
                            <a name="sub-menu-item" *ngIf="sub.path" [routerLink]="[sub.path]">{{sub.title}}</a>
                        </li>
                    </ul>
                </ng-container>
            </li>

        </ul>
    </nav>

    <div class="side-contact mb-20">
        <div class="header-menu-top-icon mb-20">
            <a name="phone-link" *ngFor="let phone of settings.contact.phones" href="{{'tel:' + phone.number}}">
                <span><span *ngIf="phone.type !== 'fix'">{{phone.person}} - </span><strong
                        class="number">{{phone.number}}</strong> <span *ngIf="phone.type === 'fix'"> (fix)</span></span>
            </a>
            <a name="email-link" href="{{'mailto:'+settings.contact.email}}"><fa-icon
                    [icon]="['fas', 'envelope']"></fa-icon>{{settings.contact.email}}</a>
            <fa-icon [icon]="['fas', 'map-marker-alt']"></fa-icon><span>{{settings.contact.address}}</span>
        </div>
    </div>

</div>