<!-- header-area-start -->
<header>
    <!--header-top-area start -->
    <div class="header-top-area d-none d-lg-block">
        <div class="container">
            <div class="header-top-inner">
                <div class="row align-items-center">
                    <div class="col-12 col-xl-9">
                        <div class="header-top-icon">
                            <a name="phone-link" href="tel:{{appService.getFirstPhone()}}">
                                <fa-icon [icon]="['fas', 'phone-flip']"></fa-icon>{{appService.getFirstPhone()}}
                            </a>
                            <a name="email-link" href="mailto:{{settings.contact.email}}"><fa-icon
                                    [icon]="['fas', 'envelope']"></fa-icon>{{settings.contact.email}}</a>
                            <fa-icon
                                [icon]="['fas', 'map-marker-alt']"></fa-icon><span>{{settings.contact.address}}</span>
                        </div>
                    </div>
                    <div class="col-3 d-none d-xl-block">
                        <div class="d-flex f-right">
                            <div class="header-social">
                                <a name="social-link" href="{{settings.social.facebook}}" target="_blank"><fa-icon
                                        [icon]="['fab', 'facebook-f']"></fa-icon></a>
                                <a name="social-link" href="{{settings.social.twitter}}" target="_blank"><fa-icon
                                        [icon]="['fab', 'twitter']"></fa-icon></a>
                                <a name="social-link" href="{{settings.social.instagram}}" target="_blank"><fa-icon
                                        [icon]="['fab', 'instagram']"></fa-icon></a>
                                <a name="social-link" href="{{settings.social.youtube}}" target="_blank"><fa-icon
                                        [icon]="['fab', 'youtube']"></fa-icon></a>
                                <a name="social-link" href="{{settings.social.linkedin}}" target="_blank"><fa-icon
                                        [icon]="['fab', 'linkedin']"></fa-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- header-top-area end -->
    <div class="header-main3 transpaerent-header sticky-header"
        [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}">
        <div class="container">
            <div class="header-main-wrapper">
                <div class="row align-items-center">
                    <div class="col-5 col-md-6 col-lg-3">
                        <div class="header-logo">
                            <a name="home-link" [routerLink]="['/']"><img class="logo-white"
                                    src="assets/img/logo/lectoform-logo-new-light.webp" alt="Lectoform Consulting"
                                    title="Lectoform Consulting" width="276" height="70"></a>
                            <a name="home-link" [routerLink]="['/']">
                                <img class="logo-black" src="assets/img/logo/lectoform-logo-new-small.webp"
                                    alt="Lectoform Consulting" title="Lectoform Consulting" width="170" height="43"></a>
                        </div>
                    </div>
                    <div class="col-7 col-md-6 col-lg-9">
                        <div class="header-main-right d-flex justify-content-end">
                            <div class="main-menu mr-30 main-menu-lg-block">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li><a name="menu-item" [routerLink]="['/']">Acasă</a></li>
                                        <li class="menu-item-has-children"><a name="menu-item"
                                                [routerLink]="['/cursuri']">Cursuri</a>
                                            <ul class="sub-menu">
                                                <li><a name="sub-menu-item" [routerLink]="''"
                                                        (click)="goToFilteredCourses('online')">Cursuri Online</a></li>
                                                <li><a name="sub-menu-item" [routerLink]="''"
                                                        (click)="goToFilteredCourses('local')">Cursuri Locale</a></li>
                                                <li><a name="sub-menu-item" [routerLink]="''"
                                                        [routerLink]="['/lista-cursuri']">Listă Cursuri</a></li>
                                            </ul>
                                            <fa-icon [icon]="['fas', 'angle-down']" class="mr-10"></fa-icon>
                                        </li>
                                        <li class="menu-item-has-children"><a name="menu-item"
                                                [routerLink]="['/locatii']">Locații</a>
                                            <ul class="sub-menu">
                                                <li><a name="sub-menu-item" [routerLink]="['/statiuni']">Stațiuni</a>
                                                </li>
                                                <li><a name="sub-menu-item" [routerLink]="['/locatii']">Locații</a></li>
                                                <li><a name="sub-menu-item" [routerLink]=""
                                                        (click)="downloadFile(assets.download_ghid_hotelier?.path, assets.download_ghid_hotelier?.name)">{{assets.download_ghid_hotelier?.label
                                                        || 'Ghid Hotelier'}}</a></li>
                                            </ul>
                                            <fa-icon [icon]="['fas', 'angle-down']" class="mr-10"></fa-icon>
                                        </li>
                                        <li class="menu-item-has-children"><a name="menu-item"
                                                [routerLink]="''">Info</a>
                                            <ul class="sub-menu">
                                                <li><a name="sub-menu-item" [routerLink]="['/cine-suntem']">Cine
                                                        Suntem</a></li>
                                                <li><a name="sub-menu-item" [routerLink]="['/lectori']">Lectori
                                                        Dedicați</a></li>
                                                <li><a name="sub-menu-item" [routerLink]="['/gdpr']">GDPR</a></li>
                                                <li><a name="sub-menu-item" [routerLink]="['/politica-cookie']">Politica
                                                        Cookie</a></li>
                                                <li><a name="sub-menu-item"
                                                        [routerLink]="['/termeni-si-conditii']">Termeni și Condiții</a>
                                                </li>
                                            </ul>
                                            <fa-icon [icon]="['fas', 'angle-down']" class="mr-10"></fa-icon>
                                        </li>
                                        <li><a name="menu-item" [routerLink]="['/contact']">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="header-btn">
                                <button name="enroll-button" mat-raised-button color="primary" (click)="goToEnroll()">
                                    <fa-icon [icon]="['fas', 'user-plus']"></fa-icon><span
                                        class="d-none d-sm-inline">Înscrieri</span>
                                </button>
                                <div class="menu-bar ml-20">
                                    <button name="menu-toggle-button" type="button" class="side-toggle header-3"
                                        (click)="drawer.toggle()">
                                        <div class="bar-icon header-3">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</header>
<!-- header-area-end -->