import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, delay, map, Observable, of, Subject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Location } from '../shared/models/location.model';
import { Resort } from '../shared/models/resort.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class LocationsService {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    apiPath: string;
    isBrowser: boolean;
    locationsFilterPayoload: {
        search?: string;
        resorts?: string[]
    } = {};
    locationsFilterPayloadSubject: BehaviorSubject<{
        search?: string;
        resorts?: string[]
    }> = new BehaviorSubject({});


    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.apiPath = this.isBrowser ? environment.BACKEND_URL : environment.BACKEND_FULL_URL;
    }

    getLocations(payload?: any): Observable<Location[]> {
        return this.http.post(`${this.apiPath}/public/locations`, payload).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getResorts(): Observable<Resort[]> {
        return this.http.get(`${this.apiPath}/public/locations/resorts-filter`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getLocationByUrl(locationUrl): Observable<Resort[]> {
        return this.http.get(`${this.apiPath}/public/locations/url/${locationUrl}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getLocationSessions(locationId): Observable<any[]> {
        return this.http.get(`${this.apiPath}/public/locations/session/${locationId}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    // HELPERS
}
