<mat-sidenav-container class="lecto-container">
    <mat-sidenav #drawer class="main-sidenav" [mode]="'over'" [position]="'end'" [fixedInViewport]="true"
        [autoFocus]="false">
        <ng-scrollbar>
            <div scrollViewport>
                <app-mobilemenu [drawer]="drawer"></app-mobilemenu>
            </div>
        </ng-scrollbar>
    </mat-sidenav>

    <mat-sidenav-content class="lecto-content">
        <sticky-button class="sticky-button" *ngIf="isSticky" [@inOutHorizontal]></sticky-button>
        <app-header [drawer]="drawer"></app-header>
        <router-outlet></router-outlet>
        <app-footerone></app-footerone>
    </mat-sidenav-content>
</mat-sidenav-container>