import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Asset } from '../../models/asset.model';
import { Settings } from '../../models/settings.model';
import { IconsModule } from '../../modules/icons.module';

@Component({
  selector: 'app-footerone',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    IconsModule
  ],
  templateUrl: './footerone.component.html',
  styleUrls: ['./footerone.component.scss']
})
export class FooteroneComponent implements OnInit {
  settings: Settings
  assets: Asset[];
  mainPhone: any;

  constructor(
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.settings = this.appService.settings;
    this.assets = this.appService.lawAssets;
    this.mainPhone = this.getMainPhone();
  }

  downloadFile(path, name) {
    this.appService.downloadFile(path, name);
  }

  getAssetLabel(options) {
    return options.find(option => option.purpose === 'download_document_legislatie').label;
  }

  getMainPhone() {
    return this.settings.contact.phones.find(phone => phone.type === 'fix').number;
  }

}
