<!-- footer-area-start -->
<footer>
   <div class="footer-area pt-100">
      <div class="container">
         <div class="footer-main mb-60">
            <div class="d-flex flex-column flex-lg-row flex-lg-fill justify-content-between items-align-start">

               <div class="footer-widget f-w1 mb-40">
                  <div class="footer-img">
                     <a name="home-link" [routerLink]="['']"><img src="assets/img/logo/lectoform-logo-new-light.webp"
                           alt="Lectoform Consulting" title="Lectoform Consulting" width="276" height="70"></a>
                     <p>{{settings.company.description}}</p>
                  </div>
                  <div class="footer-icon">
                     <a name="social-link" name="home-link" href="{{settings.social.facebook || '#'}}"
                        target="_blank"><fa-icon [icon]="['fab', 'facebook-f']"></fa-icon></a>
                     <a name="social-link" href="{{settings.social.twitter || '#'}}" target="_blank"><fa-icon
                           [icon]="['fab', 'twitter']"></fa-icon></a>
                     <a name="social-link" href="{{settings.social.instagram || '#'}}" target="_blank"><fa-icon
                           [icon]="['fab', 'instagram']"></fa-icon></a>
                     <a name="social-link" href="{{settings.social.linkedin || '#'}}" target="_blank"><fa-icon
                           [icon]="['fab', 'linkedin']"></fa-icon></a>
                  </div>
               </div>

               <div class="footer-widget f-w4 mb-40">
                  <h3>Companie</h3>
                  <ul>
                     <li><span>{{settings.company?.name}}</span></li>
                     <li><span>{{settings.contact?.address}}</span></li>
                     <li><span>TEL: {{mainPhone}}</span></li>
                     <li><span>CUI: {{settings.company?.cui}}</span></li>
                     <li><span>J: {{settings.company?.j}}</span></li>
                  </ul>
               </div>
               
               <div class="footer-widget f-w3 mb-40">
                  <h3>Utile</h3>
                  <ul>
                     <li><a name="page-link" [routerLink]="['/gdpr']">GDPR</a>
                     <li><a name="page-link" [routerLink]="['/politica-cookie']">Politica Cookie</a>
                     <li><a name="page-link" [routerLink]="['/politica-de-achizitie']">Politica de Achiziție</a>
                     <li><a name="page-link" [routerLink]="['/servicii-de-calatorie']">Servicii de Călătorie</a>
                     <li><a name="page-link" [routerLink]="['/termeni-si-conditii']">Termeni și Condiții</a>
                        <!-- <li *ngFor="let document of assets">
                        <a name="download-link" [routerLink]=""
                           (click)="downloadFile(document.path, document.name)">{{getAssetLabel(document.options)}}</a>
                     </li> -->
                  </ul>
               </div>

               <div class="footer-widget f-w2 mb-40">
                  <h3>Info</h3>
                  <ul>
                     <li><a name="page-link" [routerLink]="['/cine-suntem']" rel="nofollow">Cine suntem</a></li>
                     <li><a name="page-link" [routerLink]="['/lectori']">Lectori Dedicați</a></li>
                     <li><a name="page-link" [routerLink]="['/portofoliu']" rel="nofollow">Portofoliu</a></li>
                     <li><a name="page-link" [routerLink]="['/reduceri']" rel="nofollow">Reduceri</a></li>
                     <li><a name="page-link" [routerLink]="['/contact']">Contact</a></li>
                  </ul>
               </div>


            </div>
            <div
               class="d-flex flex-column flex-lg-row flex-lg-fill justify-content-between justify-content-lg-center items-align-start bottom-banners">
               <a href="https://anpc.ro/ce-este-sal/" target="_blank" class="me-3"><img
                     [src]="'assets/img/banner/SAL.svg'"></a>
               <a href="https://ec.europa.eu/consumers/odr" target="_blank"><img
                     [src]="'assets/img/banner/SOL.svg'"></a>
            </div>
         </div>
         <div class="copyright-area">
            <div class="container">
               <div class="row">
                  <div class="col-12 col-lg-4">
                     <div class="copyright-text d-flex justify-content-start justify-content-lg-center">
                        <p>Copyright © 2022 by <br />
                           <a name="copyright-link" href="https://www.lectoform.ro/"
                              target="_blank"><span>{{settings.title}}</span></a><br />
                           Developed by <a name="developer-link"
                              href="https://www.linkedin.com/in/mircea-sandru-474a3379"
                              target="_blank"><span>{{'SMI'}}</span></a>
                        </p>
                     </div>
                  </div>
                  <div class="col-12 col-lg-4">
                     <div class="copy-right-support justify-content-start justify-content-lg-center">
                        <div class="copy-right-svg">
                           <svg xmlns="http://www.w3.org/2000/svg" width="43.945" height="50" viewBox="0 0 43.945 50">
                              <g id="headphones" transform="translate(-31)">
                                 <g id="Group_2171" data-name="Group 2171" transform="translate(36.859 20.508)">
                                    <g id="Group_2170" data-name="Group 2170">
                                       <path id="Path_2983" data-name="Path 2983"
                                          d="M95.395,210A4.4,4.4,0,0,0,91,214.395v11.914a4.395,4.395,0,1,0,8.789,0V214.395A4.4,4.4,0,0,0,95.395,210Z"
                                          transform="translate(-91 -210)" fill="#E47545" />
                                    </g>
                                 </g>
                                 <g id="Group_2173" data-name="Group 2173" transform="translate(31 23.669)">
                                    <g id="Group_2172" data-name="Group 2172">
                                       <path id="Path_2984" data-name="Path 2984"
                                          d="M33.93,243.6a7.268,7.268,0,0,1,.125-1.234A4.386,4.386,0,0,0,31,246.529v6.055a4.386,4.386,0,0,0,3.054,4.163,7.268,7.268,0,0,1-.125-1.234Z"
                                          transform="translate(-31 -242.366)" fill="#E47545" />
                                    </g>
                                 </g>
                                 <g id="Group_2175" data-name="Group 2175" transform="translate(48.578 20.508)">
                                    <g id="Group_2174" data-name="Group 2174">
                                       <path id="Path_2985" data-name="Path 2985"
                                          d="M227.113,210a4.4,4.4,0,0,0-4.395,4.395v11.914a4.4,4.4,0,0,0,4.395,4.395,4.335,4.335,0,0,0,1.259-.206,4.386,4.386,0,0,1-4.189,3.136h-4.664a4.395,4.395,0,1,0,0,2.93h4.664a7.333,7.333,0,0,0,7.324-7.324V214.395A4.4,4.4,0,0,0,227.113,210Z"
                                          transform="translate(-211 -210)" fill="#E47545" />
                                    </g>
                                 </g>
                                 <g id="Group_2177" data-name="Group 2177" transform="translate(71.891 23.669)">
                                    <g id="Group_2176" data-name="Group 2176">
                                       <path id="Path_2986" data-name="Path 2986"
                                          d="M449.722,242.366a7.266,7.266,0,0,1,.125,1.234v11.914a7.266,7.266,0,0,1-.125,1.234,4.386,4.386,0,0,0,3.055-4.163v-6.055A4.386,4.386,0,0,0,449.722,242.366Z"
                                          transform="translate(-449.722 -242.366)" fill="#E47545" />
                                    </g>
                                 </g>
                                 <g id="Group_2179" data-name="Group 2179" transform="translate(31)">
                                    <g id="Group_2178" data-name="Group 2178">
                                       <path id="Path_2987" data-name="Path 2987"
                                          d="M52.973,0A22,22,0,0,0,31,21.973v.037a7.253,7.253,0,0,1,3-1.361,19.02,19.02,0,0,1,37.952,0,7.256,7.256,0,0,1,3,1.361v-.037A22,22,0,0,0,52.973,0Z"
                                          transform="translate(-31)" fill="#E47545" />
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </div>
                        <div class="copyright-svg-content">
                           <p>Îți stăm la dispoziție</p>
                           <h5><a name="contact-link" [routerLink]="'/contact'">Contactează-ne</a></h5>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-lg-4">
                     <div class="copy-right-support d-flex justify-content-start justify-content-lg-center">
                        <div class="copy-right-svg">
                           <svg xmlns="http://www.w3.org/2000/svg" width="49.945" height="50.553"
                              viewBox="0 0 59.118 56.553">
                              <g id="reading" transform="translate(-17.25 -19.635)">
                                 <path id="Path_7710" data-name="Path 7710"
                                    d="M57.458,19.635a11.251,11.251,0,0,0-10.1,16.208,1.1,1.1,0,0,0,.658.565l9.1,2.89a1.1,1.1,0,0,0,.669,0l9.1-2.89a1.1,1.1,0,0,0,.658-.565,11.251,11.251,0,0,0-10.1-16.208Zm8.3,14.815-8.3,2.632-8.3-2.635a9.035,9.035,0,1,1,16.61,0Z"
                                    transform="translate(-10.649)" fill="#E47545" />
                                 <path id="Path_7711" data-name="Path 7711"
                                    d="M49.361,42.825,24.721,35.01a1.107,1.107,0,0,0-1.441,1.055V50.506a1.106,1.106,0,0,0,1.106,1.106h.936a1.767,1.767,0,0,1,1.764,1.764v3.282a1.767,1.767,0,0,1-1.764,1.764h-.936a1.107,1.107,0,0,0-1.106,1.106V72.9a1.106,1.106,0,0,0,.771,1.053l24.64,7.815a1.106,1.106,0,0,0,1.441-1.055V43.878A1.107,1.107,0,0,0,49.361,42.825ZM47.92,79.2,25.493,72.089V60.63A3.983,3.983,0,0,0,29.3,56.656V53.375A3.983,3.983,0,0,0,25.493,49.4V37.577L47.92,44.69Z"
                                    transform="translate(-2.217 -5.635)" fill="#E47545" />
                                 <path id="Path_7712" data-name="Path 7712"
                                    d="M88.648,35.169a1.1,1.1,0,0,0-.988-.162L63.022,42.824a1.107,1.107,0,0,0-.772,1.055V80.715a1.106,1.106,0,0,0,1.441,1.055l24.64-7.815A1.106,1.106,0,0,0,89.1,72.9V59.525A1.107,1.107,0,0,0,88,58.419h-.936A1.767,1.767,0,0,1,85.3,56.655V53.373a1.767,1.767,0,0,1,1.764-1.764H88A1.106,1.106,0,0,0,89.1,50.5V36.061a1.105,1.105,0,0,0-.455-.893ZM86.89,49.4a3.983,3.983,0,0,0-3.806,3.973v3.282a3.983,3.983,0,0,0,3.806,3.974v11.46L64.463,79.2V44.686L86.89,37.576Z"
                                    transform="translate(-16.547 -5.633)" fill="#E47545" />
                                 <path id="Path_7713" data-name="Path 7713"
                                    d="M23.1,57.8H21.227a3.983,3.983,0,0,0-3.977,3.977v3.282a3.983,3.983,0,0,0,3.977,3.977H23.1a3.983,3.983,0,0,0,3.977-3.977V61.777A3.983,3.983,0,0,0,23.1,57.8Zm1.764,7.259A1.767,1.767,0,0,1,23.1,66.823H21.227a1.766,1.766,0,0,1-1.764-1.764V61.777a1.766,1.766,0,0,1,1.764-1.764H23.1a1.767,1.767,0,0,1,1.764,1.764Z"
                                    transform="translate(0 -14.034)" fill="#E47545" />
                                 <path id="Path_7714" data-name="Path 7714"
                                    d="M101.055,57.8H99.177A3.983,3.983,0,0,0,95.2,61.777v3.282a3.983,3.983,0,0,0,3.977,3.977h1.878a3.983,3.983,0,0,0,3.977-3.977V61.777A3.983,3.983,0,0,0,101.055,57.8Zm1.764,7.259a1.766,1.766,0,0,1-1.764,1.764H99.177a1.767,1.767,0,0,1-1.764-1.764V61.777a1.767,1.767,0,0,1,1.764-1.764h1.878a1.766,1.766,0,0,1,1.764,1.764Z"
                                    transform="translate(-28.664 -14.034)" fill="#E47545" />
                                 <g id="Group_2954" data-name="Group 2954" transform="translate(26.125 38.943)">
                                    <path id="Path_7715" data-name="Path 7715"
                                       d="M85.544,50.224l-14.514,4.6a1.106,1.106,0,1,0,.668,2.109l14.514-4.6a1.106,1.106,0,1,0-.668-2.109Z"
                                       transform="translate(-45.617 -50.172)" fill="#E47545" />
                                    <path id="Path_7716" data-name="Path 7716"
                                       d="M81.228,62.395l-10.2,3.235a1.107,1.107,0,1,0,.67,2.109L81.9,64.5a1.107,1.107,0,1,0-.67-2.109Z"
                                       transform="translate(-45.617 -54.647)" fill="#E47545" />
                                    <path id="Path_7717" data-name="Path 7717"
                                       d="M82.424,73.184,71.03,76.8a1.106,1.106,0,0,0,.334,2.161,1.12,1.12,0,0,0,.334-.052l11.394-3.613a1.106,1.106,0,1,0-.668-2.11Z"
                                       transform="translate(-45.618 -58.614)" fill="#E47545" />
                                    <path id="Path_7718" data-name="Path 7718"
                                       d="M85.544,83.49l-14.514,4.6a1.106,1.106,0,0,0,.334,2.161A1.121,1.121,0,0,0,71.7,90.2l14.514-4.6a1.106,1.106,0,0,0-.668-2.109Z"
                                       transform="translate(-45.618 -62.404)" fill="#E47545" />
                                    <path id="Path_7719" data-name="Path 7719"
                                       d="M47.242,54.827l-14.514-4.6a1.106,1.106,0,1,0-.668,2.109l14.514,4.6a1.106,1.106,0,1,0,.668-2.109Z"
                                       transform="translate(-31.287 -50.172)" fill="#E47545" />
                                    <path id="Path_7720" data-name="Path 7720"
                                       d="M49.752,65.629l-10.2-3.235a1.106,1.106,0,1,0-.67,2.109l10.2,3.235a1.107,1.107,0,1,0,.67-2.109Z"
                                       transform="translate(-33.797 -54.647)" fill="#E47545" />
                                    <path id="Path_7721" data-name="Path 7721"
                                       d="M49.056,76.8,37.662,73.184a1.106,1.106,0,1,0-.669,2.109l11.394,3.613a1.12,1.12,0,0,0,.334.052,1.106,1.106,0,0,0,.334-2.161Z"
                                       transform="translate(-33.101 -58.614)" fill="#E47545" />
                                    <path id="Path_7722" data-name="Path 7722"
                                       d="M47.242,88.093l-14.514-4.6A1.106,1.106,0,1,0,32.06,85.6l14.514,4.6a1.12,1.12,0,0,0,.334.052,1.106,1.106,0,0,0,.334-2.161Z"
                                       transform="translate(-31.287 -62.404)" fill="#E47545" />
                                 </g>
                              </g>
                           </svg>
                        </div>
                        <div class="copyright-svg-content">
                           <p>Abonează-te la newsletter</p>
                           <h5><a name="subscribe-link"
                                 href="https://lectoform.us18.list-manage.com/subscribe?u=bb4ef0e8ba6ff3d5be673963b&id=7c2a75b493"
                                 target="_blank">Abonează-te</a></h5>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
<!-- footer-area-end -->