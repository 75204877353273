import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnrollService } from './enroll.service';

@Injectable()
export class EnrolledErrorResolve implements Resolve<any> {
    constructor(
        private enrollService: EnrollService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const transactionId = route.params['transactionId'];

        return forkJoin({
            transaction: this.enrollService.getEnrolledError(transactionId),
        }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.error('ENROLLED RESOLVE ERROR: ', error);
                this.router.navigate(['/inscrieri']);
                return throwError(() => error.error);
            })
        );

    }
}