import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Settings } from 'src/app/shared/models/settings.model';
import { MappedAssets } from '../../models/asset.model';
import { IconsModule } from '../../modules/icons.module';
import { MaterialModule } from '../../modules/material.module';
import { FilterService } from '../../services/filter.service';
import { MobilemenuComponent } from './mobilemenu/mobilemenu.component';
import { CoursesService } from 'src/app/courses/courses.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    IconsModule,
    MaterialModule,
    MobilemenuComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() drawer;
  isSticky: boolean = false;
  settings: Settings;
  assets: MappedAssets;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 100;
  }

  constructor(
    private router: Router,
    public appService: AppService,
    public filterService: FilterService,
    private coursesService: CoursesService
  ) { }

  ngOnInit(): void {
    this.settings = this.appService.settings;
    this.assets = this.appService.mappedAssets;
  }

  goToFilteredCourses(type) {
    this.filterService.setQueryParam('tip', type, '/cursuri');
  }

  downloadFile(path, name) {
    this.appService.downloadFile(path, name);
  }

  goToEnroll(): void {
    this.router.navigate(['/inscrieri']);
    this.coursesService.removeSelectedOptions();
  }

}
