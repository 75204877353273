import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SitemapService } from './sitemap.service';

@Injectable()
export class SitemapResolve implements Resolve<any> {
    constructor(
        private sitemapService: SitemapService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return forkJoin({
            sitemap: this.sitemapService.getSitemap(),
        }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.error('SITEMAP RESOLVE ERROR: ', error);
                this.router.navigate(['/error/404']);
                return throwError(() => error.error);
            })
        );
    }
}