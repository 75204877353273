import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';

import { Observable, of, tap } from 'rxjs';
import { DataStateService } from '../services/data-state.service';
import {AppService} from "../../app.service";


@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {

    constructor(
        public dataStateService: DataStateService,
        private appService: AppService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
      const transferKey = this.appService.getTransferKey(request.urlWithParams);
      if (request.method === 'GET') {
          const storedResponse: any = this.dataStateService.getDynamicStateKey(transferKey);
        if (storedResponse) {
                const response = new HttpResponse({ body: storedResponse, status: 200 });
                return of(response);
            }
        }

        return next.handle(request);
    }

}
