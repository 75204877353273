import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ResortsService } from './resorts.service';

@Injectable()
export class ResortResolve implements Resolve<any> {
    constructor(
        private resortsService: ResortsService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const resortUrl = route.params['resortUrl'];

        return forkJoin({
            resort: this.resortsService.getResortByUrl(resortUrl),
        }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.error('RESORT RESOLVE ERROR: ', error);
                this.router.navigate(['/statiuni']);
                return throwError(() => error.error);
            })
        );

    }
}