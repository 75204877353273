import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocationsService } from '../locations/locations.service';
import { FilterService } from '../shared/services/filter.service';
import { CoursesService } from './courses.service';

@Injectable()
export class CoursesResolve implements Resolve<any> {
    constructor(
        private filterService: FilterService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const queryParams = route.queryParams;

        return forkJoin({
            resort: queryParams['statiune'] ? this.filterService.getResortData(queryParams['statiune']) : of(null),
            course: queryParams['curs'] ? this.filterService.getCourseData(queryParams['curs']) : of(null)
        }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.error('LOCATION RESOLVE ERROR: ', error);
                return throwError(() => error.error);
            })
        );
    }
}