<app-breadcrumb [breadcrumbBanner]="'assets/img/banner/404-banner.webp'"
    [breadcrumbTitle]="'404 - Pagina nu a fost gasită...'">
</app-breadcrumb>

<!-- member-area-start -->
<section class="not-found-area pb-45">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-wrapper mb-30">
                    <img [src]="'assets/img/banner/404.webp'" alt="404 not found" title="404 not found" width="2000" height="1587">
                </div>
            </div>

        </div>
    </div>
</section>