import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconsModule } from '../../modules/icons.module';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    IconsModule
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbTitle: string | undefined;
  @Input() breadcrumbSubTitle: string | undefined;
  @Input() breadcrumbBanner: string | undefined; 
  @Input() breadcrumbList: any;
  @Input() content?: boolean = false;
  
  constructor() {
  }

  ngOnInit(): void {

  }

}
