import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Course } from '../shared/models/course.model';
import { Resort } from '../shared/models/resort.model';
import { AppService } from '../app.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

export interface FilterData {
    courses: Course[];
    resorts: Resort[];
    ranges: {
        range: string;
        start: string;
        end: string;
    }[];
}

@Injectable({
    providedIn: 'root'
})
export class SitemapService {
    apiPath: string;
    isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient,
        private appService: AppService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.apiPath = this.isBrowser ? environment.BACKEND_URL : environment.BACKEND_FULL_URL;
    }

    getSitemap(): Observable<any> {
        return this.http.get(`${this.apiPath}/sitemap.xml`, { responseType: 'text' }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.log(error.error.message);
                return throwError(() => error.error);
            })
        );
    }

}