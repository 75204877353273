import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoursesService } from '../courses.service';

@Injectable()
export class CourseWithSessionResolve implements Resolve<any> {
    constructor(
        private coursesService: CoursesService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const courseUrl = route.params['courseUrl'];
        const sessionUrl = route.params['sessionUrl'];

        return forkJoin({
            course: this.coursesService.getCourseByUrl(courseUrl),
            session: this.coursesService.getSessionByUrl(sessionUrl),
            sessions: this.coursesService.getCourseSessionsByUrl(courseUrl)
        }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.error('COURSE WITH SESSION RESOLVE ERROR: ', error);
                this.router.navigate(['/cursuri']);
                return throwError(() => error.error);
            })
        );

    }
}