<div class="sticky-container">
    <button name="toggle-popup" mat-flat-button class="sticky-button" (click)="toggle = !toggle" *ngIf="!toggle"
        [@inOutAnimation]>
        <fa-icon [icon]="['fas', 'phone']" class="mr-5"></fa-icon>
    </button>

    <div class="popup" *ngIf="toggle" [@inOutAnimation]>
        <div class="popup-header d-flex flex-row justify-content-between items-align-center">
            <span><strong>Ai întrebări?</strong><br> Îți stăm la dispoziție.</span>
            <button name="close-popup" mat-flat-button
                class="close-button d-flex flex-row justify-content-center items-align-center"
                (click)="toggle = !toggle">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </button>
        </div>



        <div class="popup-content first-section">
            <a name="send-message" class="message-group" [routerLink]="'/contact'">
                <fa-icon [icon]="['fas', 'message']" class="mr-10"></fa-icon>
                <span class="link">Trimite-ne mesaj</span>
            </a>
        </div>
        <div class="popup-content second-section">
            <a *ngFor="let phone of settings.contact.phones" href="{{'tel:' + phone.number}}">
                <div class="phone-group d-flex flex-column">
                    <span class="person" *ngIf="phone.type !== 'fix'">{{phone.person}}</span>
                    <span class="number">{{phone.number}}</span> <span *ngIf="phone.type === 'fix'">
                        (fix)</span>
                </div>
            </a>
        </div>

    </div>
</div>