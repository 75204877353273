import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PageService } from './page.service';

@Injectable()
export class PageResolve implements Resolve<any> {
    constructor(
        private pageService: PageService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const pageurl = route.params['url'];

        return forkJoin({
            page: this.pageService.getPageByUrl(pageurl),
        }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.error('PAGE RESOLVE ERROR: ', error);
                this.router.navigate(['/error/404']);
                return throwError(() => error.error);
            })
        );
    }
}