<!-- hero-area-start -->
<div class="breadcrumb-area"
   [ngStyle]="{'background-image': breadcrumbBanner ? 'url(' + breadcrumbBanner + ')' : 'url(' + '../assets/img/banner/courses-banner5.webp' + ')' }">

   <div class="container h-100">
      <div class="row h-100 d-flex flex-row justify-content-between">
         <div class="breadcrumn-column" [ngClass]="content ? 'col-5' : 'col-12'">
            <div class="breadcrumb-title d-flex flex-row align-items-center">
               <div class="titles">
                  <h2>
                     {{breadcrumbTitle}}
                  </h2>
                  <h3 *ngIf="breadcrumbSubTitle">{{breadcrumbSubTitle}}</h3>
               </div>
            </div>
            <div class="breadcrumb-nav">
               <nav>
                  <ul class="breadcrumb">
                     <li class="breadcrumb-item">
                        <a name="home-link" [routerLink]="['/']">Acasa</a>
                     </li>
                     <li class="breadcrumb-item" *ngFor="let item of breadcrumbList">
                        <a name="breadcrumb-link" [routerLink]="[item.path]" *ngIf="item.path">
                           <span>{{item.label}}</span>
                        </a>
                        <span *ngIf="!item.path">{{item['label']}}</span>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>

         <div class="col-7 d-flex flex-column justify-content-center align-items-center" *ngIf="content">
            <ng-content></ng-content>
         </div>
      </div>
   </div>
</div>
<!-- hero-area-end -->