export const environment = {
  production: true,
  DOMAIN: 'lectoform.ro',
  BACKEND_URL: '/api',
  BACKEND_FULL_URL: 'http://api:8000/api',
  KEYS: {
    recaptchaKey: '6LcE7lglAAAAAM5SMIhhsi9rva43p0gtWPdHgq4w',
    mapsKey: 'AIzaSyBRhPVKM-GSEs7MPqBpJm9xfwEGBw5QreE'
  },
  gtm: {
    //id: 'GTM-NZX3V67'
    id: 'GTM-TJGRGDH'
  }
};
