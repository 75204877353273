import { Component, Inject, PLATFORM_ID, HostListener, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AppService } from './app.service';
import { inOutHorizontal } from './shared/animations/slide-in-out.animation';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { FooteroneComponent } from './shared/components/footer/footerone.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { StickyButtonComponent } from './shared/components/sticky-button/sticky-button.component';
import { MaterialModule } from './shared/modules/material.module';
import { MobilemenuComponent } from './shared/components/header/mobilemenu/mobilemenu.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FooteroneComponent,
    HeaderComponent,
    MobilemenuComponent,
    NgScrollbarModule,
    StickyButtonComponent,
    MaterialModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    inOutHorizontal
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: any;
  routerSubscription: Subscription = new Subscription();
  title = 'lecto-ui';
  isBrowser: boolean;
  isSticky: boolean;


  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private appService: AppService,
    private ccService: NgcCookieConsentService,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.isBrowser) {
      this.appService.scrollTop.next(document.body.scrollTop || document.documentElement.scrollTop);
      if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    }
  }

  ngOnInit() {
    if (this.isBrowser && this.ccService.hasAnswered()) this.ccService.toggleRevokeButton(false);
    if (this.isBrowser && environment.production) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          // console.log('new url: ', item);
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);

          setTimeout(() => {
            this.drawer.close();
          }, 100);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

}

// loadGtm() {
//   let head = this.document.getElementsByTagName('head')[0];
//   let gtmScript = this.document.createElement('script');
//   gtmScript.appendChild(this.document.createTextNode(`
//     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//     })(window,document,'script','dataLayer','${environment.gtm.id}');
//   `));
//   head.appendChild(gtmScript);

//   let body = this.document.getElementsByTagName('body')[0];
//   let gtmNoScript = this.document.createElement('noscript') as HTMLScriptElement;
//   let gtmIframe = this.document.createElement('iframe') as HTMLIFrameElement;
//   gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtm.id}`;
//   gtmIframe.setAttribute('height', '0');
//   gtmIframe.setAttribute('width', '0');
//   gtmIframe.setAttribute('style', 'display:none;visibility:hidden');

//   gtmNoScript.appendChild(gtmIframe);
//   body.insertBefore(gtmNoScript, this.document.body.firstChild);
// }