import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';

import {
    faTimes,
    faSearch,
    faPhoneFlip,
    faPhone,
    faMapMarkerAlt,
    faEnvelope as faEnvelopeSolid,
    faPaperPlane,
    faUserPlus,
    faAngleDown,
    faCheckCircle,
    faCheck,
    faDownload,
    faFilter,
    faCircleXmark,
    faChevronDown,
    faMedal,
    faCalendar,
    faCalendarCheck,
    faCalendarAlt,
    faCoins,
    faChalkboardUser,
    faChalkboardTeacher,
    faFileCircleCheck,
    faLocationDot,
    faLayerGroup,
    faListSquares,
    faMapLocationDot,
    faBoxes,
    faXmark,
    faAnglesRight,
    faGlobe,
    faStar,
    faBed,
    faChevronRight,
    faGraduationCap,
    faHashtag,
    faInfoCircle,
    faExclamationCircle,
    faQuoteRight,
    faLink,
    faMapPin,
    faExclamationTriangle,
    faTimesCircle,
    faHandshakeSimple,
    faMessage,
    faBuildingFlag,
    faChevronLeft,
    faPlus,
    faMinus,
    faBriefcase,
    faCubes,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFolderOpen,
    faClock,
    faEnvelope as faEnvelopeRegular,
} from '@fortawesome/free-regular-svg-icons';

import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faYoutube,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    exports: [
        FontAwesomeModule
    ]
})

export class IconsModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faTimes, faTimesCircle, faSearch, faPhone, faPhoneFlip, faEnvelopeSolid, faEnvelopeRegular, faMapMarkerAlt, faFacebookF, faTwitter, faInstagram, faYoutube, faLinkedin, faPaperPlane, faUserPlus, faAngleDown, faCheckCircle, faCheck, faDownload, faFilter, faCircleXmark, faChevronDown, faMedal, faCalendar, faCalendarCheck, faCalendarAlt, faCoins, faChalkboardUser, faChalkboardTeacher, faFileCircleCheck, faLocationDot, faLayerGroup, faListSquares, faMapLocationDot, faBoxes, faXmark, faAnglesRight, faClock, faFolderOpen, faGlobe, faStar, faBed, faChevronRight, faGraduationCap, faHashtag, faInfoCircle, faExclamationCircle, faQuoteRight, faLink, faMapPin, faExclamationTriangle, faHandshakeSimple, faMessage, faBuildingFlag, faChevronRight, faChevronLeft, faPlus, faMinus, faBriefcase, faCubes);
        config.autoAddCss = false; /* eslint-disable import/first */
    }
}
