import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../modules/material.module';
import { IconsModule } from '../../modules/icons.module';
import { AppService } from 'src/app/app.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Settings } from '../../models/settings.model';
import { RouterLink } from '@angular/router';
// import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'sticky-button',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    IconsModule,
    RouterLink,
    // NgScrollbarModule
  ],
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, right: '-200px' }),
            animate('400ms ease',
              style({ opacity: 1, right: '0px' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, right: '0px' }),
            animate('400ms ease',
              style({ opacity: 0, right: '-200px' }))
          ]
        )
      ]
    )
  ]
})
export class StickyButtonComponent implements OnInit {
  settings: Settings;
  toggle: boolean = false;

  constructor(
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.settings = this.appService.settings;
  }

}
