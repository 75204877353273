import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Enroll } from '../shared/models/enroll.model';
import { AppService } from '../app.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class EnrollService {
    apiPath: string;
    isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.apiPath = this.isBrowser ? environment.BACKEND_URL : environment.BACKEND_FULL_URL;
    }

    enrollUser(payload?: Enroll): Observable<any> {
        return this.http.post(`${this.apiPath}/public/enroll`, payload).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getEnrolledUser(id?: any): Observable<any> {
        return this.http.get(`${this.apiPath}/public/enrolled/${id}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }
    getEnrolledError(id: any): Observable<any> {
        return this.http.get(`${this.apiPath}/public/not-enrolled/${id}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getRomanianLocations(filter): Observable<any> {
        let params = new HttpParams();
        if (filter) params = params.append('search', filter.toString());

        return this.http.get(`${this.apiPath}/public/autocomplete/locations`, { params }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

}
