import { Route } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageResolve } from './page/page.resolve';
import { SitemapResolve } from './sitemap/sitemap.resolve';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    loadComponent: () => import('./home/home.component').then((x) => x.HomeComponent)
  },
  {
    path: 'cursuri',
    loadChildren: () => import('./courses/courses.routes').then(m => m.CoursesRoutes),
  },
  {
    path: 'lista-cursuri',
    loadComponent: () => import('./courses/list/list.component').then((x) => x.CoursesListComponent),
  },
  {
    path: 'locatii',
    loadChildren: () => import('./locations/locations.routes').then(m => m.LocationsRoutes),
  },
  {
    path: 'statiuni',
    loadChildren: () => import('./resorts/resorts.routes').then(m => m.ResortsRoutes),
  },
  {
    path: 'inscrieri',
    loadChildren: () => import('./enroll/enroll.routes').then(m => m.EnrollRoutes),
  },
  {
    path: 'contact',
    loadComponent: () => import('./contact/contact.component').then((x) => x.ContactComponent),
  },
  {
    path: 'lectori',
    loadComponent: () => import('./instructors/instructors.component').then(m => m.InstructorsComponent),
  },
  {
    path: 'sitemap',
    loadComponent: () => import('./sitemap/sitemap.component').then((x) => x.SitemapComponent),
    resolve: {
      data: SitemapResolve
    }
  },
  {
    path: ':url',
    loadComponent: () => import('./page/page.component').then(m => m.PageComponent),
    resolve: {
      data: PageResolve
    }
  },
  {
    path: 'error/404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];
