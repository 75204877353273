import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Location } from '../shared/models/location.model';
import { Resort } from '../shared/models/resort.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ResortsService {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    apiPath: string;
    isBrowser: boolean;
    locationsFilterPayoload: {
        search?: string;
    } = {};
    locationsFilterPayloadSubject: BehaviorSubject<{
        search?: string;
    }> = new BehaviorSubject({});


    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.apiPath = this.isBrowser ? environment.BACKEND_URL : environment.BACKEND_FULL_URL;
    }

    getResorts(payload?: any): Observable<Resort[]> {
        return this.http.post(`${this.apiPath}/public/locations/resorts`, payload).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getResortByUrl(resortUrl): Observable<Resort[]> {
        return this.http.get(`${this.apiPath}/public/locations/resort/url/${resortUrl}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    getResortLocations(resortId): Observable<Location[]> {
        return this.http.get(`${this.apiPath}/public/locations/resort/${resortId}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

    // HELPERS
}
