import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '../shared/components/breadcrumb/breadcrumb.component';
import { RouterLink } from '@angular/router';
import { MaterialModule } from '../shared/modules/material.module';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    RouterLink,
    MaterialModule,
  ],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

}
