import {
    HttpClient,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, map, Observable, throwError, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Page } from '../shared/models/page.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class PageService {
    apiPath: string;
    isBrowser: boolean;
    page: Page;
    pageSubject: Subject<Page> = new Subject();

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.apiPath = this.isBrowser ? environment.BACKEND_URL : environment.BACKEND_FULL_URL;
    }

    getPageByUrl(url): Observable<Page> {
        return this.http.get(`${this.apiPath}/public/pages/${url}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

}
